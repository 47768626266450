<template>
  <card-component
    title="システムログイン"
    icon="lock"
    :has-card-header-background="true"
    :has-button-slot="true"
  >
    <form @submit.prevent="willLogin" autocomplete="off">
      <b-field label="管理番号">
        <b-input
          type="text"
          icon="account"
          placeholder="0123"
          v-model="form.code"
          required
          autofocus
        />
      </b-field>
      <b-field label="パスワード">
        <b-input
          type="text"
          icon="key"
          v-model="form.password"
          required
          class="password-binput"
        />
      </b-field>

      <hr />

      <b-field grouped>
        <div class="control">
          <button
            type="submit"
            class="button is-black"
            :class="{ 'is-loading': isLoading }"
          >
            ログイン
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CardComponent from '@/components/CardComponent'
import { AuthForm } from '@/types/auth'
import BaseMixin from '@/baseMixin'

export default {
  mixins: [BaseMixin],
  components: { CardComponent },
  data: () => {
    return {
      form: new AuthForm()
    }
  },
  computed: {
    ...mapGetters('storeAuth', ['isManageRole', 'isInstructorRole', 'isShopRole'])
  },
  methods: {
    ...mapActions('storeAuth', ['login']),
    willLogin () {
      this.isLoading = true
      this.login({ authForm: this.form }).then(() => {
        this.$buefy.snackbar.open({
          message: 'ログインしました',
          queue: false
        })
        if (this.isManageRole) {
          this.$router.replace({ name: 'Dashboard' })
        }
        if (this.isShopRole) {
          this.$router.replace({ name: 'Checkin' })
        }
        if (this.isInstructorRole) {
          this.$router.replace({ name: 'Billing' })
        }
      }).catch(this.handleError)
    }
  }
}
</script>
